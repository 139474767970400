/*-----RESULTS PAGE-----*/


#results #resultsWrap{
	width: 100%;
}

#results .resultBox{
	width: 98%;
	//height: 440px;
	//overflow:scroll;
	margin: 15px 0 0 0;
	//overflow:auto;
}


#results .resultRow{
	width: 96%;
	height: 1.9em;

	.rowSubmit{
		float: left;
		width: 82%;
	}

	.squareBtnLink{
		float: left;
		width: 4%;
		min-width: 2em;
		height: 1.9em;
		margin-left: 1%;

	}

	.overviewLink{
		margin-left: 1%;
		float: left;
		width: 4.2%;
		min-width: 1.8em;
		
	}
}

#results .resultField{
	float: right;
	margin: 0.25em 0.5em;
}


#results{

	.fromTable{
		float: left;
		background-color: #333;
		color: #fff;
		margin: 0.05em 0;
		padding: 0.1em 0 0.2em;
		border-radius: 0.2em;
		font-size: 1.1em;
		width: 7em;
		&:before{
			margin-right: 0.3em;
		}

	}

	.FTcontacts:before{
		@extend .fa, .fa-user;
	}

	.FTaddress:before{
		@extend .fa, .fa-map-marker;
	}

	.FTprojects:before{
		@extend .fa, .fa-map;
	}

	.FTservicing:before{
		@extend .fa, .fa-wrench;
	}

	


	.matchedEntry{
		float: left;
		font-weight: bold;
	}


 	.primaryContactId{
		display: none;
	}

	.lastName{
		//display: none;
	}

	.postcode{
		//display: none;
	}
}






