/*----------TABLET----------*/

@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
	
	
	@media screen and (device-width: 768px) and (device-aspect-ratio: 3/4)
	{
		/*----------iPad----------*/
	}
	
}
