/*----------CORE UI----------*/

.diskBtn{
	width: 18px;
	height: 18px;
	line-height: 18px;
	text-indent: 6px;
	font-size: 0.87em;
	border-radius: 10px;
	background-color: #999;
	color: #fff;
	text-decoration: none;
	cursor: pointer;
}

.diskBtn:hover{
	background-color: #666;
	text-decoration: none;
	color: #fff;
}


.plus{
	display: inline-block;
	width: 18px;
	height: 18px;
	background-color: #666;
	color: #fff;
	border: 2px solid #fff;
	border-radius: 16px;
	text-align: center;
	line-height: 18px;
	font-size: 14px;
	font-weight: bold;
	margin: 0 0 0 4px;
}


.plus:hover{
	background-color: #333;
	cursor: pointer;
}

div.ui-datepicker{
 font-size:12px;
}


label{
	line-height: 22px;
}

button, .squareBtnLink, .linkBtn{
	box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.4),  inset 0px 1px 4px rgba(255, 255, 255, 0.4);
	height: 26px;
	border: 0.1em solid #999;
	border-radius: 5px;
	background: rgb(238,238,238); /* Old browsers */
	background: linear-gradient(to bottom, rgba(248,248,248,1) 0%,rgba(214,214,214,1) 100%); /* W3C */
	
}



.linkBtn{
	display: inline-block;
	padding: 0px 8px;
	line-height: 26px;
	text-align: center;
	margin-top: 10px;
}

.squareBtnLink{
	display: block;
	width: 26px;
	line-height: 24px;
	text-align: center;
}

button:hover, .squareBtnLink:hover, .linkBtn:hover{
	text-decoration: none;
	cursor: pointer;
	background: rgb(238,238,238);
}


input{
	margin: -2px 4px 0 0;
	height: 20px;
	width: 245px;
	border: 2px solid #999;
	border-radius: 5px;
	background-color: #eee;
	text-indent: 6px;
	text-overflow: ellipsis;
}

input:focus{
	background-color: #fff;
}

select{
	width: 250px;
	font-size: 0.9em;
	height: 24px;
	border: 2px solid #999;
	background-color: #eee;
}

select:focus{
	background-color: #fff;
}


textarea{
	width: 243px;
	height: 80px;
	border: 2px solid #999;
	border-radius: 5px;
	background-color: #eee;
	
}

textarea:focus{
	background-color: #fff;
	
}

.checkbox{
	width: 20px;
	margin-top: 5px;
}