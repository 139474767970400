/*----------NOTICES----------*/

#notices{
	position: absolute;
	width: 100%;
	top: -100px;
	z-index:100;
}

.noticeBox{
	position: relative;
	margin: 0 auto;
	max-width: 700px;
	border-radius: 0 0 10px 10px;
	box-shadow: 0px 3px 10px #333;
}

.noticeBoxMsg{
	display: inline-block;
	margin: 18px 60px 18px 16px;
	font-size: 1.4em;

	p{
		margin: 0;
	}
	
}

.updateBox{
	background-color: rgb(206, 238, 206);
}

.errorBox{
	background-color: rgb(236, 202, 202);
}

.noticeBox .diskBtn{
	position: absolute;
	margin-top: -8px;
	bottom: 16px;
	right: 12px;
	text-indent: 4px;
	font-size: 1.3em;
	line-height: 19px;
	box-shadow: inset 1px 1px 1px #777;
	
}

.tooltip{
	position: absolute;
	display: none;
}

.tooltip .contents{
	max-width: 240px;
	background-color: #f0f0f0;
	border-radius: 10px;
	border: 1px solid;
	border-color: #eee #ccc #bbb #ddd;
	box-shadow: 2px 4px 8px #ccc;
	padding: 10px 15px 10px 15px;
	margin-left: 8px;
	
}

.tooltip .arrow{
	position: absolute;
	right: 14px;
	top: -8px;
	width: 0; 
	height: 0; 
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 10px solid #f0f0f0;
}

.tooltip .diskBtn{
	position: absolute;
	margin-top: -8px;
	bottom: 12px;
	right: 12px;
	text-indent: 4px;
	font-size: 1.3em;
	line-height: 19px;
	display: none;
	box-shadow: 0px 0px 9px 8px #f0f0f0, inset 1px 1px 1px #666;
	
}