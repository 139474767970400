/*---CLIENT OVERVIEW PAGE---*/

#connections{

	
	float: left;

	#resultsWrap{
		margin: 1%;
		width: 46%;
		min-width: 440px;
		
	}

	.resultBox{

		height: 14em;
		border: 0.2em solid #ddd;
		overflow:auto;
	}


	.resultRow{
		width: 98%;
		height: 22px;
	}

	.rowSubmit{
		width: 90%;
		float: left;
		
	}

	#contactsBox .resultRow button{
		width: 90%;
	}

	#projectsBox .resultRow button, #servicingBox .resultRow button,  #addressBox .resultRow button{
		width: 82%;
	}



	.squareBtnLink{
		min-width: 2.2em;
		width: 6.5%;
		float: left;
		height: 24px;
		margin-left: 1.5%;
		text-align: center;
		text-decoration: none;
	}

	.resultField{
		float: left;
		margin: 2px;
		
	}

	.postcode{
		float: right;
	}

	.name{
		float: right;
	}

	.ref{
		float: left;
		text-align: left;
		margin-right: 3%;
		
	}

	.addressId, .contactId, .contactType{
		display: none;
	}
	
		h2:before, h3:before{
		margin-right: 0.3em;
	}

	.contactsTitle:before{
		@extend .fa, .fa-user;
	}

	.addressTitle:before{
		@extend .fa, .fa-map-marker;
	}

	.projectsTitle:before{
		@extend .fa, .fa-map;
	}

	.servicingTitle:before{
		@extend .fa, .fa-wrench;
	}


}
