/*---CLIENT OVERVIEW PAGE---*/

#clientOverview{
	float: left;
}

#clientOverview #resultsWrap{
	margin: 1%;
	width: 46%;
	min-width: 440px;
	
}

#clientOverview .resultBox{

	height: 14em;
	border: 0.2em solid #ddd;
	overflow:auto;
}


#clientOverview .resultRow{
	width: 98%;
	height: 22px;
}

#clientOverview .rowSubmit{
	width: 90%;
	float: left;
	
}

#clientOverview #contactsBox .resultRow button{
	width: 90%;
}

#clientOverview #projectsBox .resultRow button, #clientOverview #servicingBox .resultRow button,  #clientOverview #addressBox .resultRow button{
	width: 82%;
}



#clientOverview .squareBtnLink{
	min-width: 2.2em;
	width: 6.5%;
	float: left;
	height: 24px;
	margin-left: 1.5%;
	text-align: center;
	text-decoration: none;
}

#clientOverview .resultField{
	float: left;
	margin: 2px;
	
}

#clientOverview .postcode{
	float: right;
}

#clientOverview .name{
	float: right;
}

#clientOverview .ref{
	float: left;
	text-align: left;
	margin-right: 3%;
	
}

#clientOverview{
	.addressId, .contactId, .contactType{
		display: none;
	}
}

#clientOverview{

	h2:before, h3:before{
		margin-right: 0.3em;
	}

	.contactsTitle:before{
		@extend .fa, .fa-user;
	}

	.addressTitle:before{
		@extend .fa, .fa-map-marker;
	}

	.projectsTitle:before{
		@extend .fa, .fa-map;
	}

	.servicingTitle:before{
		@extend .fa, .fa-wrench;
	}
}
