/*----------BASICS----------*/

body{
	min-width: 500px;
}

#wrapper{
	float: left;
	width: 100%;
	min-height: 600px;
	margin-top: 6em;
}

#wrapperBox{
	margin: 0 auto;
	max-width: 90%;
}

#contentBox{
	margin: 10px;
}


/*----------MAIN----------*/


#mainlogo{
	position: relative;
	top: 250px;
	margin: 0 auto;
	width: 576px;
}

#mainlogo img{
	float: left;
	width: 400px;	
}


#mainlogo p{
	float: left;
	font-size: 6.2em;
	color: #333;	
	margin: 0;
}


#serviceMsg{
	line-height: 2.5em;
	text-align: center;
}

#heading{
	float: left;
	width: 90%;
}

#resultsWrap{
	float: left;
}

.boxLabel{
	float: left;
}

.resultBox{
	width: 100%;
	margin-top: 0.3em;
	float: left;
	//border: 4px solid #ccc;
	border-radius: 12px;
	background-color: #fff;
}

.resultRow{
	float: left;
	margin: 12px 10px 0;
}



.addLabel{
	display: inline-block;
	font-size: 1.6em;
	h3{
		@extend .fa, .fa-plus-circle;
		
		&:before{
			margin-left: 0.3em; 
			
		}
		
	}

}

.addLabel:hover{
	cursor: pointer;
}


.viewAllLabel{
	float: right;
	margin: 0.5em 0.5em 0 0;
	font-size: 0.8em;

	h3{
		margin-bottom: 0;
	}

	&:hover{
		text-decoration: underline;
		cursor: pointer;
	}
}


.plus{
	background-color: #1e2a72;
}


.labelBox{
	float: left;
	width: 160px;
	text-align: right;
	margin-right: 18px;
	
}

.dataBox{
	float: left;
	width: 260px;
	margin: 3px;
	
}


.dataBox label{
	font-weight: bold;
	color: #333;
}


.currencySymbol{
	float: left;
	position: relative;
	font-size: 0.9em;
	width: 0;
	height: 0;
	top: 3px;
	left: 8px;
}

.dataBox #price{
	text-indent: 14px;
}

.resultBox h1{
	width: 100%;
	text-align: center;
	line-height: 100px;
}



.resultRow .mapsLink{

	span{
		@extend .fa, .fa-map-marker, .fa-lg;
	}

	&:hover{
		background: linear-gradient(to bottom, #F45943 0%, #D74130 100%);
		color: #000;
	}
}

.resultRow .calenderLink{
	
	span{
		@extend .fa, .fa-calendar, .fa-lg;
	}

	&:hover{
		background: linear-gradient(to bottom, #4787FA 0%, #3563D4 100%);
		color: #fff;
	}
}

.resultRow .overviewLink{
	
		@extend .fa, .fa-users, .fa-lg;
	
	&:hover{
		background: linear-gradient(to bottom, lighten($mantairBlue, 5%) 0%, darken($mantairBlue, 15%) 100%);
		color: #fff;
	}
}


fieldset{
	border: none;
}
