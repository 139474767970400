body{
	font-family: Arial, sans-serif;
	color:#000;
	font-size:0.8em;
	line-height: 1;
}

h1{
	font-size:2em;
	color:#000000;
	letter-spacing: 1px;
	font-weight: normal;
	margin: 30px 0 10px;
}

h2{
	font-size:1.8em;
	color:#000000;
	letter-spacing: 1px;
	font-weight: normal;
	margin: 2em 0 1em;
}

h3{
	font-size:1.6em;
	font-weight: normal;
	color: #000;
	margin: 0 0 0.2em;
}

a{
	color:#000000;
	text-decoration: none;
	
}

a:hover{
	color:#000000;
	text-decoration: underline;
}

.rcFont{
	font-family: 'Damion', cursive;
}
