/*-----DASHBOARD PAGE-----*/


#dash #resultsWrap{
	width: 100%;
}

#dash .resultBox{
	height: 440px;
	overflow:scroll;
	overflow:auto;
}


#dash .resultRow{
	width: 98%;
	height: 1.9em;

	.rowSubmit{
		float: left;
		width: 90%;
	}

	.squareBtnLink{
		float: left;
		width: 3%;
		min-width: 2em;
		height: 1.9em;
		margin-left: 1%;
	}	
}

#dash .rowSubmit{
	width: 100%;
}

#dash .resultField{
	float: left;
	margin: 2px;
}


#dash .houseName{
	float: right;
} 

#dash .numberStreetName{
	float: right;
} 

#dash .postcode{
	float: right;
	font-weight: bold;
}

#dash .primaryContactId{
	display: none;
}

#dash .companyName{
	margin-right: 6px;
	font-weight: bold;
}