/*-------DETAILS PAGE-------*/

#details #resultsWrap{
	width: 100%;
}


#details .resultBox{
	width: 98%;
	margin: 20px 0 40px 0;
}



#details ol{
	list-style-type: none;
	margin: 20px 0;
}

#details li{
	width: 100%;
	float: left;
}

#details li div{
	margin: 3px 0;
}

#details .labelBox{
	float: left;
	width: 160px;
	text-align: right;
	margin-right: 18px;
	
}

#details .dataBox{
	float: left;
	width: 260px;
	margin: 3px;
	
}


#details .valMsg{
	float: left;
	line-height: 20px;
	color: #aa0000;
	font-weight: bold;
	display: none;
}


#details button{
	margin: 10px 0 20px 180px;
}

