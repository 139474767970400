/*----------HANDHELD----------*/
@media screen and (max-device-width: 766px){
	
	@media screen and (device-width: 320px) and (device-aspect-ratio: 40/71)
	{
		/*----------iPhone5----------*/
	}
	
	@media screen and (device-width: 320px) and (device-aspect-ratio: 2/3) {
		
		@media
		only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (   min--moz-device-pixel-ratio: 2),
		only screen and (     -o-min-device-pixel-ratio: 2/1),
		only screen and (        min-device-pixel-ratio: 2),
		only screen and (                min-resolution: 192dpi),
		only screen and (                min-resolution: 2dppx) {
			/*----------iPhone4----------*/
		}
	
		@media
		only screen and (-webkit-max-device-pixel-ratio: 1),
		only screen and (   max--moz-device-pixel-ratio: 1),
		only screen and (     -o-max-device-pixel-ratio: 1/1),
		only screen and (        max-device-pixel-ratio: 1),
		only screen and (                max-resolution: 191dpi),
		only screen and (                max-resolution: 1dppx) {
			/*----------iPhone3GS----------*/
		}
		
	}
	
	
}


