/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */

.#{$fa-css-prefix}-calendar:before { content: $fa-var-calendar; }
.#{$fa-css-prefix}-map-marker:before { content: $fa-var-map-marker; }
.#{$fa-css-prefix}-plus-circle:before { content: $fa-var-plus-circle; }
.#{$fa-css-prefix}-times:before { content: $fa-var-times; }

.#{$fa-css-prefix}-user:before { content: $fa-var-user; }
.#{$fa-css-prefix}-users:before { content: $fa-var-users; }
.#{$fa-css-prefix}-map:before { content: $fa-var-map; }
.#{$fa-css-prefix}-wrench:before { content: $fa-var-wrench; }