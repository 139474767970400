/*-----------LOGIN-----------*/

#login{
	width: 100%;
	height: 3.3em;
	background-color: $mantairBlue;
	position: fixed;
	z-index:40;
		
}

#loginBar{
	margin: 0 auto;
	max-width: 90%;
	
}

#loginReadout{
	font-size: 1.2em;
	margin: 0.9em 0 0 0.5em;
	float: left;
	color: #fff;
}

#loginform{
	margin: 8px 6px 0 0;
	float: right;
}

#loginform input{
	//border-color: #666;
	height: 22px;
	border:none;
}



#loginform .innerLabel{
	position: absolute;
	width: 0;
	height: 0;
	margin-left: -19.3em;
	top: 10px;
	color: #777;
	pointer-events:none;
	visibility: hidden;
}

#loginform .forgotBtn{
	position: absolute;
	margin-left: -2.85em;
	top: 1.1em;
	
}