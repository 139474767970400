#sessionDebug{

	position: fixed;
	bottom: 0;
	width:88%;
	background-color: rgba(20, 20, 20, .95);
	padding: 1em 6%;
	max-height: 50%;
	overflow: scroll;
	font-family: 'Courier New';
	color: white;




	.green{
		color: green;
	}

	.red{
		color: red;
	}

	li{
		padding: 0.2em;	
	}

	#closeDebug{
		cursor: pointer;
		position: absolute;
		right:6%;
		top:1em;
		&:before{
			font-size: 2em;
		}
		@extend .fa, .fa-times;
	}

}