/*--------NAVAGATION--------*/

.nav{
	width: 100%;
	position: fixed;
	z-index:50;
	top:3.3em;
}

#openNav{
	background-color: #ddd;
	font-size: 1.6em;
	height: 44px;

}

#userNav{
	background-color: #ddd;
	font-size: 1em;
	height: 2.8em;
}

.navBar{
	margin: 0 auto;
	max-width: 90%;
	
}

.navBar ul{
	list-style: none;
	margin: 0;
	padding-left: 0.5em;
}

.navBar li{
	margin: 0.9em 0.2em;
	display: list-item;
	float: left;
	
}

.navBar a{
	background-color: #fff;
	text-decoration: none;
	border-radius: 4px;
	padding: 4px 8px;
	
}

.navBar a:hover{
	background-color: $mantairBlue;
	color: #fff;
	
}

.navBar .active{
	background-color: $mantairBlue;
	color: #fff;
}

.navBar .navTitle{
	font-size: 1.6em;
	margin: 0.4em 2em 0 0;
}

#searchBox{
	float: right;
	margin: 7px 4px 0 0;
}

#searchBox input{
	border-radius: 20px;
	border: 0.2em solid #ccc;
	background-color: #f0f0f0;
	width: 160px;
	text-indent: 12px;
	
}

#searchBox input:focus{
	background-color: #fff;
	outline: 0;
	
}


#searchBox .innerLabel{
	width: 0;
	height: 0;
	margin-left: -155px;
	top: 8px;
	color: #666;
	pointer-events:none;
}


/*--------BREADCRUMBS--------*/

#breadcrumbs{
	width: 100%;
	float: left;
	margin: 0 25px 0;
	clear: both;
}

#breadcrumbs ul{
	list-style: none;
}

.breadcrumb{
	margin: 10px -12px 0px -25px;
	display: list-item;
	float: left;
	
}

.breadcrumb .arrow1{
content:'';
display:block;
position:relative;
float:left;
top:-4px;
left:20px;
width:0px;
height:0;
border-color: transparent transparent transparent #fff; 
border-style: solid;
border-width: 11px;
z-index: 0;
pointer-events:none;
}

.breadcrumb .arrow2{
content:'';
display:block;
position:relative;
float:right;
top:-4px;
left: -2px;
width:0;
height:0;
border-color: transparent transparent transparent #ddd; 
border-style: solid;
border-width: 11px;
z-index: 4;
pointer-events:none;
}


.breadcrumb a{
	text-decoration: none;
	border-radius: 4px;
	padding: 4px 8px 4px 16px;
	background-color: #ddd;
}

.breadcrumb:hover a{
	background-color: $mantairBlue;
	color: #fff;
}

.breadcrumb:hover .arrow2{	
	border-color: transparent transparent transparent $mantairBlue;
}

#breadcrumbs .active a{
	background-color: $mantairBlue;
	color: #fff;
}

.firstcrumb a{
	padding-left: 10px;

}