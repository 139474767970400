/*     
		   _|_|_|  _|    _|  _|_|_|_|_|  _|_|_|_|_|  _|_|_|_|  _|_|_|        _|      _|    _|_|    _|      _|          _|  _|      
		 _|        _|    _|      _|          _|      _|        _|    _|      _|_|  _|_|  _|    _|    _|  _|            _|  _|      
		 _|        _|    _|      _|          _|      _|_|_|    _|_|_|        _|  _|  _|  _|_|_|_|      _|          _|  _|  _|  _|      
		 _|        _|    _|      _|          _|      _|        _|    _|      _|      _|  _|    _|      _|              _|  _|      
		   _|_|_|    _|_|        _|          _|      _|_|_|_|  _|    _|      _|      _|  _|    _|      _|              _|  _| 
*/                             
                                                                                                                                           
$mantairBlue: #273795;

$facebookBlue: #3b5998;

$twitterBlue: #00acee;

$youtubeRed: #D7281E;

$gplusRed: #D24335;